<template>
  <v-row justify="center">
    <v-dialog :value="value" max-width="700px" persistent>
      <v-card class="mx-auto mt-3 card" width="100%">
        <v-toolbar dark color="#177F83" class="d-flex justify-center">
          <v-toolbar-title align-center
            >تعديل معلومات المستخدم
          </v-toolbar-title>
        </v-toolbar>
        <v-form ref="edit_user">
          <v-card-text class="mt-7">
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6">
                <Input
                  @update-value="user_info.name = $event"
                  :value="user_info.name"
                  type="text"
                  label="الاسم"
                  :rules="userNameRules" />
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <Input
                  @update-value="user_info.user_name = $event"
                  :value="user_info.user_name"
                  type="text"
                  label="اسم المستخدم"
                  :rules="userNameRules" />
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-text-field
                  color="#159895"
                  v-model="password"
                  outlined
                  rounded
                  hide-details="auto"
                  clearable
                  class="font-weight-black"
                  :type="showPassword ? 'text' : 'password'"
                  label="كلمة المرور"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :rules="PasswordsRules" />
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6">
                <Input
                  @update-value="user_info.phone_number = $event"
                  :value="user_info.phone_number"
                  type="number"
                  label="رقم الهاتف"
                  :rules="phoneNumberRules" />
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12">
                <Selects
                  @update-value="user_type = $event"
                  :value="user_type"
                  itemValue="value"
                  itemText="account_type"
                  :items="filteredItems"
                  label="نوع الحساب"
                  :rules="RulseAll" />
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-autocomplete
                  v-if="type_user == 0"
                  ref="clinicDropdown"
                  :items="medical_clinic"
                  v-model="id"
                  color="#159895"
                  hide-details="auto"
                  item-text="clinic_name"
                  item-value="id"
                  label="العيادة"
                  :menu-props="menu_props"
                  outlined
                  rounded
                  clearable
                  lazy-rules
                  class="font-weight-black">
                  <template v-slot:append-item>
                    <div
                      v-intersect="userInteract"
                      v-if="!($store.state.users.clinic_state == 'finished')"
                      class="text-lg-center"></div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mt-2 pb-5 mr-3">
            <v-btn
              @click="edit_medical_clinic"
              :loading="loading"
              color="#159895"
              class="px-4 ml-3"
              elevation="4">
              <h4 style="color: white; font-size: 17px">تحديث</h4>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-btn outlined color="#C62828" v-on:click="$emit('popClose')">
              <h4>اغلاق</h4>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import Input from "@/components/layout/Input.vue";
  import Selects from "@/components/layout/Selects.vue";
  export default {
    components: { Input, Selects },
    data() {
      return {
        id: null,
        user_type: null,
        password: null,
        showPassword: false,
        menu_props: {
          closeOnClick: false,
          closeOnContentClick: false,
          disableKeys: true,
          openOnClick: false,
          maxHeight: 300,
          offsetY: true,
          offsetOverflow: true,
          transition: false,
        },
        user_ty: [
          { account_type: "مدير عيادة", value: 1, type: 0 },
          { account_type: "دكتور", value: 2, type: 1, type2: 0 },
          { account_type: "سكرتير", value: 3, type: 1, type2: 0 },
          { account_type: "صيدلي", value: 4, type: 1, type2: 0 },
        ],
        userNameRules: [
          (value) => !!value || "هذا الحقل مطلوب",
          (value) =>
            (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
        ],
        PasswordsRules: [
          (value) => !!value || "هذا الحقل مطلوب",
          (value) =>
            (value || "").length >= 6 || "الحد الادنى لعدد الاحرف هوه 6",
        ],
        phoneNumberRules: [
          (value) => !!value || "هذا الحقل مطلوب",
          (value) =>
            (value || "").length <= 11 ||
            "الحد الاعلى لارقام المسموحه هوه 11 رقم",
        ],
        RulseAll: [(v) => !!v || "هذا الحقل مطلوب"],
        user_info: {},
      };
    },
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      user: {
        type: Object,
        default: null,
      },
    },
    computed: {
      medical_clinic() {
        return this.$store.state.users.medical_clinic;
      },
      type_user() {
        return this.$store.state.user_type;
      },
      clinic_id() {
        return this.$store.state.clinic_id;
      },
      loading() {
        return this.$store.state.users.pop_loading;
      },
      filteredItems() {
        return this.user_ty.filter(
          (opation) =>
            opation.type == this.type_user || opation.type2 == this.type_user
        );
      },
    },
    methods: {
      // تعديل المستخدم
      edit_medical_clinic() {
        if (this.$refs.edit_user.validate()) {
          let data = {};
          data["id"] = this.user_info.id;

          if (this.type_user == 1) {
            data["clinic_id"] = this.clinic_id;
          } else {
            data["clinic_id"] = this.id;
          }
          data["name"] = this.user_info.name;
          data["user_name"] = this.user_info.user_name;
          data["phone_number"] = this.user_info.phone_number;
          data["password"] = this.password;
          data["user_type"] = this.user_type.value;
          this.$store.dispatch("users/edit_user", data).then(() => {
            this.$emit("popClose");
          });
        }
      },
      get_clinics() {
        if (this.$store.state.users.clinic_state == "finished") return;
        this.$store.dispatch("users/get_clinics");
      },
      userInteract(entries, observer, isIntersecting) {
        if (isIntersecting) {
          setTimeout(() => {
            this.get_clinics(); // onscroll
            this.$refs.clinicDropdown.onScroll();
          }, 500);
        }
      },
    },
    watch: {
      user() {
        this.user_info = this.user;
        this.id = this.user.clinic_id;
        if (this.user.user_type == 1) {
          this.user_type = { account_type: "مدير عيادة", value: 1, type: 0 };
        } else if (this.user.user_type == 2) {
          this.user_type = {
            account_type: "دكتور",
            value: 2,
            type: 1,
            type2: 0,
          };
        } else if (this.user.user_type == 3) {
          this.user_type = {
            account_type: "سكرتير",
            value: 3,
            type: 1,
            type2: 0,
          };
        } else if (this.user.user_type == 4) {
          this.user_type = {
            account_type: "صيدلي",
            value: 4,
            type: 1,
            type2: 0,
          };
        } else {
          this.user_type = { account_type: "مدير نظام", value: 0 };
        }
      },
    },
  };
</script>
<style scoped>
  .card {
    border-radius: 25px !important;
    box-shadow: 0px 0px 0px 0px !important;
  }
</style>
